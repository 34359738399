import React from 'react'

const InputField = ({ label, value, onChange, type = "text", placeholder, disabled }) => (
  <div>
    <label className="block text-sm font-medium text-gray-300 mb-2">
      {label}
    </label>
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);

export default InputField