import { useState } from "react";
import { auth, dataBase } from "../firebaseConnection";
import { Upload, Loader2, MoveLeft } from "lucide-react";
import { signOut } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { supabase } from "../supabaseClient";
import SelectField from "../components/SelectField";
import InputField from "../components/InputField";

const ManageProperties = () => {
  const [carouselFileToBeUploaded, setCarouselFileToBeUploaded] = useState([]);
  const [mainFileToBeUploaded, setMainFileToBeUploaded] = useState(null);
  const [agentFileToBeUploaded, setAgentFileToBeUploaded] = useState(null);
  const [newPropertyName, setNewPropertyName] = useState("");
  const [newPropertyType, setNewPropertyType] = useState("Apartamento");
  const [newPropertyDescription, setNewPropertyDescription] = useState("");
  const [newPropertyNeighborhood, setNewPropertyNeighborhood] = useState("");
  const [newPropertyAddress, setNewPropertyAddress] = useState("");
  const [newPropertyLatitude, setNewPropertyLatitude] = useState("");
  const [newPropertyLongitude, setNewPropertyLongitude] = useState("");
  const [newPropertyBathrooms, setNewPropertyBathrooms] = useState("");
  const [newPropertySuites, setNewPropertySuites] = useState("");
  const [newPropertyBedrooms, setNewPropertyBedrooms] = useState("");
  const [newPropertySurface, setNewPropertySurface] = useState("");
  const [newPropertyPrice, setNewPropertyPrice] = useState("");
  const [newAgentName, setNewAgentName] = useState("");
  const [newAgentNumber, setNewAgentNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    await signOut(auth);
  };

  const uploadCarouselImages = async (files) => {
    const urls = [];
    for (const file of files) {
      const fileName = `${Date.now()}-${file.name}`;
      const { data: uploadData, error } = await supabase.storage
        .from("properties")
        .upload(`carousel/${fileName}`, file);

      if (error) {
        console.error("Erro:", error);
        continue;
      }

      // Correção aqui ↓
      const { data: urlData } = supabase.storage
        .from("properties")
        .getPublicUrl(uploadData.path);

      urls.push(urlData.publicUrl); // Note o "l" minúsculo
    }
    return urls;
  };

  const uploadMainImage = async (file) => {
    try {
      const fileName = `${Date.now()}-${file.name}`;
      const { data: uploadData, error } = await supabase.storage
        .from("properties")
        .upload(`main/${fileName}`, file);

      if (error) throw error;

      // Correção aqui ↓
      const { data: urlData } = supabase.storage
        .from("properties")
        .getPublicUrl(uploadData.path);

      return urlData.publicUrl; // Note o "l" minúsculo
    } catch (error) {
      console.error(`Erro: ${error.message}`);
      return null;
    }
  };

  const uploadAgentImage = async (file) => {
    try {
      const fileName = `${Date.now()}-${file.name}`;
      const { data: uploadData, error } = await supabase.storage
        .from("properties")
        .upload(`agent/${fileName}`, file);

      if (error) throw error;

      const { data: urlData } = supabase.storage
        .from("properties")
        .getPublicUrl(uploadData.path);

      return urlData.publicUrl;
    } catch (error) {
      console.error(`Erro na imagem principal: ${error.message}`);
      return null;
    }
  };

  const clearFields = () => {
    setAgentFileToBeUploaded(null);
    setCarouselFileToBeUploaded([]);
    setMainFileToBeUploaded(null);
    setNewPropertyName("");
    setNewPropertyType("");
    setNewPropertyDescription("");
    setNewPropertyNeighborhood("");
    setNewPropertyAddress("");
    setNewPropertyLatitude("");
    setNewPropertyLongitude("");
    setNewPropertyBathrooms("");
    setNewPropertySuites("");
    setNewPropertyBedrooms("");
    setNewPropertySurface("");
    setNewPropertyPrice("");
    setNewAgentName("");
    setNewAgentNumber("");
  };

  const handleSubmitProperty = async (e) => {
    e.preventDefault();

    if (
      !newPropertyName ||
      !newPropertyType ||
      !newPropertyDescription ||
      !newPropertyNeighborhood ||
      !newPropertyAddress ||
      !newPropertyLatitude ||
      !newPropertyLongitude ||
      !newPropertyBathrooms ||
      !newPropertySuites ||
      !newPropertyBedrooms ||
      !newPropertySurface ||
      !newPropertyPrice ||
      !newAgentName ||
      !newAgentNumber ||
      !mainFileToBeUploaded ||
      !agentFileToBeUploaded ||
      carouselFileToBeUploaded.length === 0
    ) {
      toast.error("Verifique se não há nenhum campo inválido...");
      return;
    }
    setLoading(true);
    // Call the image upload functions
    const mainPictureUrl = await uploadMainImage(mainFileToBeUploaded);
    const carouselPicturesUrl = await uploadCarouselImages(
      carouselFileToBeUploaded
    );
    const agentPictureUrl = await uploadAgentImage(agentFileToBeUploaded);

    await addDoc(collection(dataBase, "properties"), {
      userId: auth?.currentUser?.uid,
      propertyName: newPropertyName,
      propertyType: newPropertyType,
      propertyDescription: newPropertyDescription,
      propertyNeighborhood: newPropertyNeighborhood,
      propertyAdress: newPropertyAddress,
      propertyLatitude: newPropertyLatitude,
      propertyLongitude: newPropertyLongitude,
      propertyBathrooms: newPropertyBathrooms,
      propertySuites: newPropertySuites,
      propertyBedrooms: newPropertyBedrooms,
      propertySurface: newPropertySurface,
      propertyPrice: newPropertyPrice,
      agentName: newAgentName,
      agentNumber: newAgentNumber,
      mainPicture: mainPictureUrl,
      carouselPictures: carouselPicturesUrl,
      agentPicture: agentPictureUrl,
    })
      .then(() => {
        toast.success("Propriedade registrada com sucesso.");
        setLoading(false);
        clearFields();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro inesperado: " + error);
      });
  };

  const FileUploadInput = ({ label, multiple, onChange, file }) => (
    <div>
      <label className="block text-sm font-medium text-gray-300 mb-2">
        {label}
      </label>
      <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-gray-600 rounded-lg cursor-pointer hover:border-blue-500 transition-colors bg-gray-700 relative">
        <div className="flex flex-col items-center text-gray-400">
          <Upload className="w-6 h-6 mb-2" />
          <span className="text-sm">Clique para enviar</span>
          <span className="text-xs text-gray-500">
            PNG, JPG, JPEG (max. 5MB)
          </span>
        </div>

        {/* Feedback visual dos arquivos selecionados */}
        {file && (
          <div className="absolute bottom-2 left-2 text-xs text-gray-400">
            {multiple ? `${file.length} arquivo(s) selecionado(s)` : file.name}
          </div>
        )}

        <input
          type="file"
          className="hidden"
          multiple={multiple}
          onChange={(e) => {
            if (multiple) {
              onChange(Array.from(e.target.files)); // Converte FileList para array
            } else {
              onChange(e.target.files[0]);
            }
          }}
          accept="image/*"
        />
      </label>
    </div>
  );

  const options = ["Apartamento", "Casa", "Terreno"];

  return (
    <div className="min-h-screen py-8 px-4 mb-14">
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader2 className="animate-spin h-12 w-12 text-blue-500" />
        </div>
      )}

      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-white">Cadastro de Imóveis</h1>
          <Link
            to="/admin"
            className="flex items-center gap-2 px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg transition-colors"
          >
            <MoveLeft className="w-5 h-5" />
            Voltar
          </Link>
        </div>

        <form
          onSubmit={handleSubmitProperty}
          className="space-y-6 bg-gray-800 p-6 rounded-xl drop-shadow-lg"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Seção Informações Básicas */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Informações Básicas
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  label="Nome da Propriedade*"
                  value={newPropertyName}
                  onChange={setNewPropertyName}
                  placeholder="Ex: Residencial Green Valley"
                />
                <SelectField
                  label="Tipo de Imóvel*"
                  value={newPropertyType}
                  onChange={setNewPropertyType}
                  options={options}
                />
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Descrição*
                  </label>
                  <textarea
                    rows={4}
                    value={newPropertyDescription}
                    onChange={(e) => setNewPropertyDescription(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Descreva detalhes importantes do imóvel..."
                  />
                </div>
              </div>
            </div>

            {/* Seção Localização */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Localização
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  label="Endereço*"
                  value={newPropertyAddress}
                  onChange={setNewPropertyAddress}
                  placeholder="Rua, número"
                />
                <InputField
                  label="Bairro*"
                  value={newPropertyNeighborhood}
                  onChange={setNewPropertyNeighborhood}
                  placeholder="Nome do bairro"
                />
                <InputField
                  label="Latitude*"
                  type="number"
                  value={newPropertyLatitude}
                  onChange={setNewPropertyLatitude}
                  placeholder="Ex: -23.550650"
                />
                <InputField
                  label="Longitude*"
                  type="number"
                  value={newPropertyLongitude}
                  onChange={setNewPropertyLongitude}
                  placeholder="Ex: -46.633382"
                />
                <div className="text-gray-600 text-sm mb-2">
                  <strong>DICA:</strong> Entre no{" "}
                  <a
                    href="https://www.geocords.com/address-to-lat-long/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    Geo Cords
                  </a>{" "}
                  para pegar latitude e longitude.
                </div>
              </div>
            </div>

            {/* Seção Detalhes da Propriedade */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Detalhes da Propriedade
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <SelectField
                  label="Banheiros*"
                  value={newPropertyBathrooms}
                  onChange={setNewPropertyBathrooms}
                  options={Array.from({ length: 11 }, (_, i) => i)}
                />
                <SelectField
                  label="Suítes*"
                  value={newPropertySuites}
                  onChange={setNewPropertySuites}
                  options={Array.from({ length: 11 }, (_, i) => i)}
                />
                <SelectField
                  label="Quartos*"
                  value={newPropertyBedrooms}
                  onChange={setNewPropertyBedrooms}
                  options={Array.from({ length: 11 }, (_, i) => i)}
                />
                <InputField
                  label="Área (m²)*"
                  type="number"
                  value={newPropertySurface}
                  onChange={setNewPropertySurface}
                  placeholder="Ex: 150"
                />
                <InputField
                  label="Valor (R$)*"
                  type="number"
                  value={newPropertyPrice}
                  onChange={setNewPropertyPrice}
                  placeholder="Ex: 500000"
                />
              </div>
            </div>

            {/* Seção Imagens */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Imagens
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <FileUploadInput
                  label="Imagem Principal*"
                  file={mainFileToBeUploaded}
                  onChange={(files) => setMainFileToBeUploaded(files)}
                />

                <FileUploadInput
                  label="Fotos do Carrossel*"
                  multiple
                  file={carouselFileToBeUploaded}
                  onChange={(files) => setCarouselFileToBeUploaded(files)}
                />

                <FileUploadInput
                  label="Foto do Corretor*"
                  file={agentFileToBeUploaded}
                  onChange={(files) => setAgentFileToBeUploaded(files)}
                />
              </div>
            </div>

            {/* Seção Dados do Corretor */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Dados do Corretor
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  label="Nome do Corretor*"
                  value={newAgentName}
                  onChange={setNewAgentName}
                  placeholder="Nome completo"
                />
                <InputField
                  label="Telefone*"
                  value={newAgentNumber}
                  onChange={setNewAgentNumber}
                  placeholder="(11) 99999-9999"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
            disabled={loading}
          >
            {loading ? (
              <Loader2 className="animate-spin h-5 w-5" />
            ) : (
              <>
                <Upload className="w-5 h-5" />
                Cadastrar Imóvel
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ManageProperties;
