import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { MonitorCog } from "lucide-react";
import { auth } from "../firebaseConnection";
import { onAuthStateChanged } from "firebase/auth";

const Header = () => {
  const [user, setUser] = useState(null);
  const pathname = useLocation().pathname;
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  
    return () => unsubscribe();
  }, []);

  return (
    <header className="py-6 mb-12">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={Logo}
              className="h-[25px] w-[25px] "
              alt="Ventura Corretoria"
            />
            <h1 className="ml-5 sm:flex hidden font-light uppercase text-gray-400">
              Soluções Imobiliárias
            </h1>
          </div>
        </Link>
        {(user && pathname === "/")  && (
          <Link
            to="/admin"
            className="flex items-center gap-2 bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg shadow-md transition-all"
          >
            <MonitorCog size={20} />
            Painel de Controle
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
